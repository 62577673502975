import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const userTimeZone = this.element.dataset.flatpickrUserTimezone; // Fetch the user's timezone from the data attribute.
    const defaultCurrentTime = this.element.dataset.defaultCurrentTime === 'true'; // Check if default_current_time is true.

    // Function to calculate the user's local time using the time zone offset
    const getUserTimeZoneTime = () => {
      const currentDate = new Date();
      const localOffset = currentDate.getTimezoneOffset(); // Get the local timezone offset in minutes
      const userOffset = new Date().getTimezoneOffset(); // Get the user's time zone offset in minutes

      // Convert time to the user's time zone
      const userTime = new Date(currentDate.getTime() - (localOffset - userOffset) * 60000);

      return { hour: userTime.getHours(), minute: userTime.getMinutes() };
    };

    const userTime = getUserTimeZoneTime();

    flatpickr(this.element, {
      enableTime: true,
      animate: false,
      wrap: true,
      disableMobile: "true",
      defaultHour: defaultCurrentTime ? userTime.hour : 12, // Set default hour based on the user's time zone
      defaultMinute: defaultCurrentTime ? userTime.minute : 0, // Set default minute based on the user's time zone
      onOpen: function (dateStr, selectedDates, instance) {
        if (instance.element.classList.contains("has-value")) {
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.remove("hidden");
        } else {
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.add("hidden");
        }
      },
      onChange: function (dateStr, selectedDates, instance) {
        if (instance.selectedDates.length === 0) {
          this.element.querySelector(".display-datetime .datetime .date").innerHTML = "";
          this.element.querySelector(".display-datetime .datetime .time").innerHTML = "";
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.add("hidden");
        } else {
          const month = instance.monthElements[0].selectedOptions[0].text.substring(0, 3); // Get month abbreviation
          const day = instance.selectedDateElem.innerText;
          const year = instance.yearElements[0].value;
          const hour = instance.hourElement.valueAsNumber;
          const minutes = instance.minuteElement.value;
          const ampm = instance.amPM.innerText.toLowerCase();

          this.element.querySelector(".display-datetime .datetime .date").innerHTML = `${month} ${day}, ${year}`;
          this.element.querySelector(".display-datetime .datetime .time").innerHTML = `${hour}:${minutes}${ampm}`;
          this.element.querySelector(".display-datetime .datetime-actions .icon.clear").classList.remove("hidden");
        }
      }
    });
  }
}
