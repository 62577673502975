// misc JS for steps/tasks

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['editable']

  editMe(event) {
    // Turn all editable parts back to 'viewing' state
    var editable_parts = this.editableTargets
    for (let editable_part of editable_parts) {
      editable_part.classList.remove("editing");
      editable_part.classList.add("viewing");
    }

    // Hide/reset all configurations (block content, automations) forms
    resetConfigurationForms(this.element);

    // Turn THIS editable part to 'editing' state
    var edit_me = event.target.closest("[data-target*='step--step.editable']");
    edit_me.classList.add("editing");
    edit_me.classList.remove("viewing");

  }

  focusMe(event) {
    var block = event.target.closest(".block");
    var block_id = block.dataset.blockId;
    var input = this.element.querySelector(".block-"+block_id+"-input");
    var rich_text_modal = event.target.closest(".trix-dialog");
    var rich_text_button = event.target.closest("button[data-trix-action='link']");

    if (!rich_text_modal) {

      // focus the input
      if (input && !rich_text_button) {
        input.focus();
      }

      // unfocus any other blocks that are currently focused
      var focused_blocks = this.element.querySelectorAll(".block.focused");
      for (let focused_block of focused_blocks) {
        focused_block.classList.remove("focused");
      }
      // focus this block
      block.classList.add("focused");
    }
  }

  updateClose(event){
    event.preventDefault();
    console.log("updateClose---")

    var step = event.target.closest(".step");
    var step_expander_id = step.dataset.stepExpanderId;
    var current_step_status = step.dataset.stepExpanderStatus;
    var parent_li = step.closest("li");
    var form = event.target.closest("form.step-content-form");

    step.classList.remove("expanded");
    parent_li.classList.remove("expanded", "no-drag");
    step.classList.add("minimized");
    parent_li.classList.add("minimized");

    var step_title = step.querySelector(".step-title");
    step_title.classList.remove("editing");
    step.dataset.stepExpanderStatus = "minimized";
    localStorage.setItem(step_expander_id, "minimized");
    // Use Rails UJS to submit form via AJAX
    Rails.fire(form, 'submit');
  }

  submitConfigureBlock(event) {
    var block_id = event.target.dataset.blockId;
    var form = event.target.closest("form.step-content-form");
    var form_action = form.action;
    var new_form_action_url = form_action + "?configured_block_id=" + block_id;
    form.action = new_form_action_url
  }

  viewMe(event) {
    var editable_part = event.target.closest("[data-target*='step--step.editable']");
    editable_part.classList.add("viewing");
    editable_part.classList.remove("editing");
  }

  viewAll() {
    var editable_parts = this.editableTargets
    for (let editable_part of editable_parts) {
      editable_part.classList.remove("editing");
      editable_part.classList.add("viewing");
    }
    var focused_fields = this.element.querySelectorAll(".focused");
    for (let focused_field of focused_fields) {
      focused_field.classList.remove("focused");
    }
  }

  resetAllConfigurationForms() {
    // Hides/resets all configurations forms
    resetConfigurationForms(this.element);
  }

    
  uppyClick(event) {
    const blockId = event.target.getAttribute("data-block-id");
    const blockElement = document.querySelector(`[data-block-id="${blockId}"]`);
    const filesAttached =
      blockElement.getAttribute("data-files-attached") === "true";
    const uppyModal = document.querySelector(`#uppyModal-${blockId}`);
    const upload_action = blockElement.getAttribute("data-upload-action");
    
    // Open the modal only if no files are attached
    if (!filesAttached) {
      uppyModal.style.display = "flex";
      uppyModal.addEventListener("click", () => {
        uppyModal.style.display = "none";
    
        // Clear the modal content to avoid caching
        document.querySelector(`#uppy-${blockId}`).innerHTML = ''; 
      })

      // Initialize Uppy for the specific modal and block
      if (!this[`uppy_${blockId}`]) {
        this[`uppy_${blockId}`] = this.initializeUppyModal(
          blockElement,
          blockId,
          upload_action
        );
      }
    }
  }


  initializeUppyModal(blockElement, blockId, upload_action) {
    const accountId = blockElement.getAttribute("data-account-id");
    const projectId = blockElement.getAttribute("data-project-id");
    const taskListId = blockElement.getAttribute("data-task-list-id");
    const taskId = blockElement.getAttribute("data-task-id");
    const companionUrl = "https://companion.uppy.io/";
    let uppyEndpoint, upload_method;

    if (upload_action === "add_more") {
      uppyEndpoint = `/${accountId}/projects/${projectId}/task_lists/${taskListId}/tasks/${taskId}/task_blocks/${blockId}/attach_more_files`;
      upload_method = "POST";
    }else{
      uppyEndpoint = `/${accountId}/projects/${projectId}/task_lists/${taskListId}/tasks/${taskId}/task_blocks/${blockId}`;
      upload_method = "PUT";
    }

    const uppy = new window.Uppy({
      restrictions: {
        maxNumberOfFiles: null, // Unlimited files
      },
    });

    // Initialize the Dashboard
    uppy.use(window.Dashboard, {
      inline: true,
      target: `#uppy-${blockId}`,
      showProgressDetails: true,
      allowMultipleUploads: true,
    });

    document
      .getElementById(`uppy-${blockId}`)
      .addEventListener("click", function (event) {
        event.stopPropagation(); // Prevent click events on the dashboard from closing the modal
      });

    // Setup XHR upload
    uppy.use(window.XHRUpload, {
      endpoint: uppyEndpoint,
      method: upload_method,
      formData: true,
      fieldName: "task_block[files][]", // Ensure this matches Rails expectation
      bundle: true,
      getResponseData: (responseText) => {
        try {
          if (
            typeof responseText === "string" &&
            (responseText.startsWith("{") || responseText.startsWith("["))
          ) {
            return JSON.parse(responseText);
          } else {
            eval(responseText.response);
            return { success: true };
          }
        } catch (error) {
          return { success: true };
        }
      },
    });

    // Handle upload completion
    const integrations = [
      { plugin: GoogleDrive, target: Dashboard },
      { plugin: Dropbox, target: Dashboard },
      { plugin: OneDrive, target: Dashboard },
      { plugin: Instagram, target: Dashboard },
      { plugin: Facebook, target: Dashboard },
      { plugin: Url, target: Dashboard },
      { plugin: Webcam, target: Dashboard },
      { plugin: ImageEditor, target: Dashboard },
    ];

    integrations.forEach(({ plugin, target }) => {
      uppy.use(plugin, {
        target,
        companionUrl,
      });
    });

    // Handle upload completion
    uppy.on('complete', (result) => {
      
      if (result.failed.length > 0) {
        console.error('Upload failed! Files:', result.failed);
      }
    });

    // Optionally, add error handling
    uppy.on('error', (error) => {
      console.error('Uppy encountered an error:', error);
    });
  }

  openModalFromPreview(event) {
    const fileUrl = event.currentTarget.getAttribute("data-file-url");
    const fileType = event.currentTarget.getAttribute("data-file-type");

    this.openModal(fileUrl, fileType);

    document.getElementById("file-modal").addEventListener("click", (event) => {
      this.closeModal(event);
    });

    document
      .getElementById("modal-preview-content")
      .addEventListener("click", function (event) {
        event.stopPropagation();
      });
  }

  openModal(fileUrl, fileType) {
    var modal = document.getElementById("file-modal");
    var modalContent = document.getElementById("modal-preview-content");
    // var docPreview = document.getElementById("doc-preview");
    modal.style.display = "flex";
    document.body.style.overflow = "hidden";

    // Clear previous content
    modalContent.innerHTML = "";

    if (fileType.startsWith("image")) {
      modalContent.innerHTML = `<img src="${fileUrl}" class="modal-image" />`;
    } else if (fileType === "application/pdf") {
      modalContent.innerHTML = `<embed src="${fileUrl}" type="application/pdf" class="pdf-preview" width="100%" height="500px" />`;
    } else if (fileType.startsWith("video")) {
      modalContent.innerHTML = `<video controls src="${fileUrl}" class="modal-video" />`;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      // Wrap DOCX preview in a container with iframe-like styling
      const docxContainer = document.createElement("div");
      docxContainer.id = "doc-preview";
      modalContent.appendChild(docxContainer);

      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          var options = {
            inWrapper: false,
            ignoreWidth: true,
            ignoreHeight: true,
          };
          docx
            .renderAsync(blob, docxContainer, null, options)
            .then(() => console.log("DOCX preview rendered"))
            .catch((err) => console.error("Error rendering DOCX:", err));
        })
        .catch((error) => console.error("Error fetching DOCX file:", error));
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      // Wrap Excel preview in a container with iframe-like styling
      const excelContainer = document.createElement("div");
      excelContainer.id = "doc-preview";
      modalContent.appendChild(excelContainer);
      new ExcelViewer(excelContainer, fileUrl, {
        theme: "dark",
        lang: "en",
      });
    } else {
      modalContent.innerHTML = `<p>Preview not available. <a href="${fileUrl}" target="_blank">Download File</a></p>`;
    }
  }

  closeModal(event) {
    var modal = document.getElementById("file-modal");
    if (event.target === modal) {
      modal.style.display = "none";
      document.body.style.overflow = "auto";
    }
  }

}


function resetConfigurationForms(step_element) {
  // Hide/reset all configurations forms
  var create_configurations_divs = step_element.querySelectorAll(".create-configurations");
  for (let create_configurations_div of create_configurations_divs) {
    create_configurations_div.classList.add("hidden");
  }
  var add_first_configurations_links = step_element.querySelectorAll(".add-first-configuration");
  for (let add_first_configurations_link of add_first_configurations_links) {
    add_first_configurations_link.classList.remove("hidden");
  }
  var add_configurations_links = step_element.querySelectorAll(".add-item");
  for (let add_configurations_link of add_configurations_links) {
    add_configurations_link.classList.remove("hidden");
  }
  var edit_configurations_options_divs = step_element.querySelectorAll(".configurations-set-options.editing-configurations-options");
  for (let edit_configurations_options_div of edit_configurations_options_divs) {
    edit_configurations_options_div.classList.remove("editing-configurations-options");
    edit_configurations_options_div.classList.remove("viewing-configurations-options");
  }
  var editing_configuration_items = step_element.querySelectorAll(".configurations-list .configuration-item.configuring");
  for (let editing_configuration_item of editing_configuration_items) {
    editing_configuration_item.classList.remove("configuring");
    editing_configuration_item.querySelector(".item-content").classList.remove("hidden");
    var form = editing_configuration_item.querySelector("form");
    form.parentNode.removeChild(form);
  }
}